<template>
  <div class="c-width service-wrap">
    <template v-for="(item, index) in servicesDesc">
      <a class="service-desc"
         :href="item.src"
         :key="index"
         v-if="item.type !== curService"
         target="_blank">
        <i :class="['icon', item.type]"></i>
        <p class="title">{{ item.title }}</p>
        <p class="text"
           v-for="text in item.textData"
           :key="text.index">
          {{ text }}
        </p>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    curService: {
      type: String,
      default: 'live'
    }
  },
  data () {
    return {
      domain: `//${window.location.host}`,
      servicesDesc: [
        {
          type: 'live',
          title: '直播服务',
          textData: ['全流程直播服务', '快速部署搭建直播业务'],
          src: `//${window.location.host}/live`
        },
        {
          type: 'vod',
          title: '点播服务',
          textData: ['一站式点播服务', '快速搭建点播平台'],
          src: `//${window.location.host}/vod`
        },
        {
          type: 'interact',
          title: '音视频互动服务',
          textData: ['多路音视频实时互动', '构建1对1、1对多的音视频', '通信'],
          src: `//${window.location.host}/interactive`
        },
        {
          type: 'doc',
          title: '文档白板服务',
          textData: ['多格式文档上传', '丰富的文档演示形式'],
          src: `//${window.location.host}/document`
        },
        {
          type: 'chat',
          title: '聊天服务',
          textData: ['完整的聊天解决方案', '多终端通讯功能支持'],
          src: `//${window.location.host}/chat`
        },
        {
          type: 'form',
          title: '表单服务',
          textData: ['专业易用、快速收集', '满足各类应用场景'],
          src: `//${window.location.host}/form`
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';

.title {
  margin: 20px 0;
  color: #555;
  font-size: 18px;
  line-height: 25px;
}

.service-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-top: 40px;

  .service-desc {
    flex: 1;
    padding: 15px 12px;
    background: #fff;
    border: 1px solid #e5e8ee;
    text-align: center;
    color: #666;

    .text {
      font-size: 16px;
      white-space: nowrap;
    }

    .icon {
      display: inline-block;
      margin-top: 38px;

      &.live {
        width: 40px;
        height: 30px;
        background: url('../../../assets/images/live-default.png') no-repeat;
        background-size: cover;
      }

      &.vod {
        width: 44px;
        height: 33px;
        background: url('../../../assets/images/vod-default.png') no-repeat;
        background-size: cover;
      }

      &.interact {
        width: 39px;
        height: 36px;
        background: url('../../../assets/images/interact-default.png') no-repeat;
        background-size: cover;
      }

      &.doc {
        width: 29px;
        height: 33px;
        background: url('../../../assets/images/document-default.png') no-repeat;
        background-size: cover;
      }

      &.chat {
        width: 40px;
        height: 32px;
        background: url('../../../assets/images/chat-default.png') no-repeat;
        background-size: cover;
      }
      &.form {
        width: 29px;
        height: 34px;
        background: url('../../../assets/images/form-default.png') no-repeat;
        background-size: cover;
      }
    }

    &:hover {
      border-color: transparent;
      box-shadow: @boxShadow;

      .title,
      .text {
        color: @mainColor;
      }

      .icon {
        &.live {
          width: 40px;
          height: 30px;
          background: url('../../../assets/images/live-hover.png') no-repeat;
          background-size: cover;
        }

        &.vod {
          width: 44px;
          height: 33px;
          background: url('../../../assets/images/vod-hover.png') no-repeat;
          background-size: cover;
        }

        &.interact {
          width: 39px;
          height: 36px;
          background: url('../../../assets/images/interact-hover.png') no-repeat;
          background-size: cover;
        }

        &.doc {
          width: 29px;
          height: 33px;
          background: url('../../../assets/images/document-hover.png') no-repeat;
          background-size: cover;
        }

        &.chat {
          width: 40px;
          height: 32px;
          background: url('../../../assets/images/chat-hover.png') no-repeat;
          background-size: cover;
        }
        &.form {
          width: 29px;
          height: 34px;
          background: url('../../../assets/images/form-hover.png') no-repeat;
          background-size: cover;
        }
      }
    }

    &:not(:first-child) {
      margin-left: 30px;
    }
  }
}
</style>
