<template>
  <div class="prodcut-fun-wrap c-width">
    <div class="product-fun"
         v-for="(item, index) in productFunData"
         :key="index">
      <div class="fun_l">
        <i :class="['iconfont', item.className, serviceType]"></i>
      </div>
      <div class="fun_r">
        <div class="title">{{ item.title }}</div>
        <div class="text">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productFunData: {
      type: Array,
      default () {
        return []
      }
    },
    serviceType: {
      type: String,
      default: 'live'
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';

.product-fun {
  display: flex;
  align-items: center;
  background: #fff;
  margin-top: 40px;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:hover {
    box-shadow: @boxShadow;

    .title,
    .text {
      color: @mainColor;
    }

    .fun_l {
      .livePush {
        background-position: -133px -37px;
      }

      .livewatch {
        background-position: -130px -155px;
      }

      .liverecord {
        background-position: -129px -255px;
      }

      .livetranscode {
        background-position: -368px -31px;
      }

      .livejianhuang {
        background-position: -365px -144px;
      }

      .livesecurity {
        background-position: -371px -249px;
      }

      .livecount {
        background-position: -609px -33px;
      }

      // 点播
      .vodupload {
        background-position: -142px -37px;
      }

      .vodwatch {
        background-position: -369px -39px;
      }

      .vodManage {
        background-position: -597px -33px;
      }

      .vodtranscode {
        background-position: -134px -139px;
      }

      .vodsecurity {
        background-position: -370px -139px;
      }

      .vodcount {
        background-position: -593px -141px;
      }

      .interactPlat {
        background-position: -163px -38px;
      }

      .interactLetter {
        background-position: -399px -35px;
      }

      .interactShare {
        background-position: -624px -36px;
      }

      .interactRecord {
        background-position: -161px -152px;
      }

      .interactLive {
        background-position: -397px -144px;
      }

      .interactjianhuang {
        background-position: -613px -140px;
      }

      .interactPs {
        background-position: -159px -244px;
      }
    }
  }

  .fun_l {
    width: 160px;

    .iconfont {
      display: block;
      margin-left: 50px;
    }

    .live {
      background: url('../../../assets/images/live/function_icon.png') no-repeat;
    }

    // 直播

    .livePush {
      width: 60px;
      height: 53px;
      background-position: -36px -37px;
    }

    .livewatch {
      width: 65px;
      height: 54px;
      background-position: -33px -155px;
    }

    .liverecord {
      width: 69px;
      height: 53px;
      background-position: -32px -255px;
    }

    .livetranscode {
      width: 68px;
      height: 65px;
      background-position: -271px -31px;
    }

    .livejianhuang {
      width: 65px;
      height: 62px;
      background-position: -268px -144px;
    }

    .livesecurity {
      width: 63px;
      height: 62px;
      background-position: -274px -249px;
    }

    .livecount {
      width: 68px;
      height: 60px;
      background-position: -498px -33px;
    }

    // 点播
    .vod {
      background: url('../../../assets/images/vod/function_icon.png') no-repeat;
    }

    .vodupload {
      width: 53px;
      height: 58px;
      background-position: -42px -37px;
    }

    .vodwatch {
      width: 65px;
      height: 55px;
      background-position: -264px -39px;
    }

    .vodManage {
      width: 60px;
      height: 66px;
      background-position: -497px -33px;
    }

    .vodtranscode {
      width: 68px;
      height: 65px;
      background-position: -34px -139px;
    }

    .vodsecurity {
      width: 63px;
      height: 62px;
      background-position: -265px -139px;
    }

    .vodcount {
      width: 68px;
      height: 60px;
      margin-right: 50px;
      background-position: -493px -141px;
    }

    // hudong
    .interact {
      background: url('../../../assets/images/interact/function_icon.png')
        no-repeat;
    }

    .interactPlat {
      width: 63px;
      height: 59px;
      background-position: -60px -38px;
    }

    .interactLetter {
      width: 57px;
      height: 66px;
      background-position: -295px -35px;
    }

    .interactShare {
      width: 54px;
      height: 62px;
      background-position: -520px -36px;
    }

    .interactRecord {
      width: 66px;
      height: 50px;
      background-position: -59px -152px;
    }

    .interactLive {
      width: 61px;
      height: 64px;
      background-position: -293px -144px;
    }

    .interactjianhuang {
      width: 65px;
      height: 62px;
      background-position: -509px -140px;
    }

    .interactPs {
      width: 70px;
      height: 65px;
      background-position: -57px -244px;
    }
  }

  .fun_r {
    margin: 25px 0;
  }

  .title {
    font-size: 24px;
    color: #555;
    line-height: 33px;
    font-weight: 500;
  }

  .text {
    font-size: 16px;
    margin-top: 10px;
    color: #666;
    line-height: 24px;
  }
}
</style>
