<template>
  <div class="service-banner gray-bcg-color">
    <div class="banner c-width">
      <div class="banner-l">
        <h3 class="title">{{ bannerData.title }}</h3>
        <div class="text"
             v-for="(item, index) in bannerData.textData"
             :key="index">
          {{ item }}
        </div>
        <div class="btn-group">
          <a :href="bannerData.href2"
             class="btn experience"
             target="_blank">Demo体验</a>
        </div>
      </div>
      <div class="banner-r">
        <img :src="src"
             alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCookieToken } from '../../../utils.js'
export default {
  props: {
    bannerData: {
      type: Object,
      default () {
        return {}
      }
    },
    src: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLogin: false
    }
  },
  mounted () {
    this.isLogin = getCookieToken()
  },
  computed: {
    consoleRegistUrl () {
      const path = this.isLogin ? 'login' : 'regist'
      const registUrl = `${window.urlConfig.consoleUrl}loginLayout/${path}`
      return registUrl
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';

.service-banner {
  height: 486px;
  color: #333;

  .banner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    .banner-r {
      img {
        max-width: 590px;
      }
    }

    .banner_l {
      padding-left: 68px;
    }

    .title {
      font-size: 42px;
      font-weight: 500;
    }
    .text {
      // margin-top: 10px;
      font-size: 24px;
    }
    .btn-group {
      a {
        font-size: 16px;
        background: @mainColor;
        color: #fff;
        font-weight: 500;

        &.experience {
          margin: 30px 0 0 45px;
        }

        &:hover {
          box-shadow: @buttonHoverShadow;
          background: #fff;
          color: @mainColor;
          border-color: transparent;
        }
      }
    }
  }
}
</style>
