<template>
  <div class="wrap">
    <div class="menu-wrap" :class="{ fix: isfixNav }">
      <div class="menu c-width">
        <div class="menu-content" :class="{ chat: isChat }">
          <div
            class="item"
            v-for="(item, index) in menuData"
            :key="index"
            :class="{ active: selectIndex === index }"
            @click="switchIndex(index)"
          >
            <a :href="item.id" @click="movetoTarget($event, item.id)">
              <el-image :src="item.src" v-if="!isfixNav" lazy></el-image>
              <p class="text">{{ item.text }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuData: {
      type: Array,
      default() {
        return []
      }
    },
    isChat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectIndex: 0,
      isfixNav: false
    }
  },
  methods: {
    switchIndex(index) {
      this.selectIndex = index
    },
    movetoTarget() {
      this.$nextTick(() => {
        // const el = document.querySelector(id)
      })
    }
  },
  mounted() {
    window.addEventListener(
      'scroll',
      () => {
        const top = document.documentElement.scrollTop
        this.isfixNav = top > 544
      },
      false
    )
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.wrap {
  height: 120px;
}
.menu-wrap {
  width: 100%;
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-content {
      text-align: center;
      display: grid;
      grid-template-columns: repeat(6, 200px);
      background: #fff;

      &.chat {
        grid-template-columns: repeat(5, 240px);
      }

      .item {
        border-bottom: 4px solid #dde6f3;
        background: #fff;
        position: relative;
        z-index: 1;
        padding: 17.5px 0;

        &.active,
        &:hover {
          border-color: @mainColor;
          box-shadow: @boxShadow;
          z-index: 2;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;

        /deep/ .el-image {
          width: 44px;
        }

        .text {
          margin-top: 10px;
          margin-bottom: 0;
          font-size: 18px;
          color: #555;
          font-weight: 500;
          // padding: 0px 64px;
        }
      }
    }
    .regist {
      width: 67px;
      height: 27px;
      text-align: center;
      background: @mainColor;
      line-height: 27px;
      color: #fff;
      border-radius: 4px;
      font-size: 14px;

      &:hover {
        background: #fff;
        color: @mainColor;
        box-shadow: @boxShadow;
      }
    }
  }

  &.fix {
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 100;
    .menu {
      .menu-content {
        display: flex;
        padding: 0;
        .item {
          width: 100px;
          border-bottom-color: transparent;
          margin-left: 25px;
          padding: 0;

          &.active,
          &:hover {
            box-shadow: none;
            border-bottom-color: @mainColor;
          }

          .iconfont {
            display: none;
          }

          .text {
            padding: 15px 0;
            margin-top: 0px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
