<template>
  <div class="advantage c-width"
       :class="{ interact: isInteract }">
    <img v-for="(item, index) in advantageData"
         :key="index"
         :src="item.src"
         alt=""
         width="570"
         height="297"
         :class="{ on: item.flag === selectFlag }" />
  </div>
</template>

<script>
export default {
  props: {
    advantageData: {
      type: Array,
      default () {
        return []
      }
    },
    isInteract: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectFlag: true
    }
  },
  mounted () {
    if (this.isInteract) {
      setInterval(() => {
        this.selectFlag = !this.selectFlag
      }, 3000)
    }
  }
}
</script>

<style lang="less" scoped>
.advantage {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    margin-top: 50px;
    transition: all 0.5s ease-in-out;
    &:hover {
      margin-top: 40px;
    }
  }

  &.interact {
    opacity: 1;
    transition: opacity 1s ease-in-out;
    margin-top: 40px;
    img {
      margin-bottom: 0;
      margin-top: 0;
    }
    img:nth-of-type(3) {
      position: absolute;
      top: 0;
      left: 0;
    }
    img:nth-of-type(4) {
      position: absolute;
      top: 0;
      right: 0;
    }
    .on {
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
  }
}
</style>
