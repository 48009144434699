<template>
  <div
    class="price c-width"
    :class="{ single: priceData.length === 1, form: isForm }"
  >
    <div class="item-wrap" v-for="(item, index) in priceData" :key="index">
      <div class="line" :style="{ backgroundColor: item.bcg }"></div>
      <div class="item">
        <div class="title">{{ item.title }}</div>
        <slot name="content"></slot>
        <slot class="price-content">
          <div class="text">{{ item.text }}</div>
        </slot>
        <div class="btn-group">
          <a
            class="btn"
            href="https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038"
            target="_blank"
            >立即咨询</a
          >
          <a class="btn" :href="item.detailUrl" target="_blank">查看详情</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    priceData: {
      type: Array,
      default () {
        return []
      }
    },
    isForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bcg: 'red'
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';

.price {
  display: flex;
  justify-content: space-between;
  margin: 40px auto 50px;
  &.single {
    justify-content: center;
  }
  &.form {
    .item-wrap {
      .item {
        .title {
          color: @hoverColor;
          font-weight: 500;
        }
        .text {
          color: #555;
          font-weight: 400;
          font-size: 28px;
        }
        .btn {
          width: 100px;
        }
        .btn-group {
          a {
            &:nth-of-type(2) {
              margin-left: 30px;
            }
          }
        }
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .item-wrap {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    border: 1px solid #e1e2e6;

    &:hover {
      border-color: transparent;
      box-shadow: @boxShadow;
    }

    .line {
      width: 100%;
      height: 4px;
    }

    .item {
      padding: 0 25px 50px;

      .title {
        padding: 45px 0;
        font-weight: 500;
        font-size: 30px;
        color: #555;
        line-height: 42px;
        border-bottom: 1px solid #eaecf3;
      }

      .text {
        margin: 50px 0;
        font-size: 36px;
        color: @hoverColor;
        font-weight: 500;
      }

      .btn-group {
        a {
          font-size: 16px;

          &:first-child {
            border: 1px solid @hoverColor;
            color: @hoverColor;

            &:hover {
              background: @hoverColor;
              color: #fff;
            }
          }

          &:nth-of-type(2) {
            margin-left: 50px;
            border: 1px solid @mainColor;
            color: @mainColor;

            &:hover {
              background: @mainColor;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
