<template>
  <div class="solution c-width">
    <el-image :src="src"
              alt=""
              lazy></el-image>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.solution {
  padding: 50px 0;
}
</style>
