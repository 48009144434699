function getCookieToken () {
  let flag = false
  const cookieArr = document.cookie.split(';')
  const tokenStr = cookieArr.filter(el => {
    const elKey = el.split('=')[0].trim()
    if (elKey === 'token') {
      return el
    }
  })
  let tokenArr = []
  if (tokenStr.length) {
    tokenArr = tokenStr[0].split('=')
    if (tokenArr[1] != -1) {
      flag = true
    } else {
      flag = false
    }
  }
  return flag
}

export { getCookieToken }
